export const Tutoring = (props) => {
  return (
    <div className='szkolenia-container' id='Szkolenia'>
      <h1 className='szkolenia-title'>Chcesz rozpocząć swoją przygodę z barberstwem?</h1>
      <div className='szkolenia-content'>
      <img src='img/logoGold.png' alt='Twoje Logo' className='logo-szkolenia' />
        <div className='szkolenia-text'>
          <div className='szkolenia-info'>
            <p>Szkolenie ze stylizacji brody - 900 zł</p>
            <p>Szkolenie indywidualne - 1300 zł</p>
            <p>Szkolenie 6-dniowe - 6500 zł</p>
            <p>Szkolenie 22-dniowe - 10.000 zł</p>
          </div>
        </div>
        <a href='tel:+48533146763' className='call-button'>Zadzwoń!</a>
      </div>
    </div>
  );
}

