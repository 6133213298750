export const About = (props) => {
  return (
    <div id='O-Nas'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            {' '}
            <img src='img/about.jpg' className='img-responsive' alt='' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='O-Nas-text'>
              <h2>O nas</h2>
              <p>{props.data ? props.data.paragraph : 'loading...'}</p>
              <p>{props.data ? props.data.paragraph2 : 'loading...'}</p>
              <p>{props.data ? props.data.paragraph3 : 'loading...'}</p>
              <h3>Godziny otwarcia</h3>
              <div className='list-style'>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
              </div>
            </div>
          </div>
         
        </div> <div className="tekst">
          Zarezerwuj swoją wizytę na <a href="https://booksy.com/pl-pl/101921_pan-brzytwa-barber-shop_barber-shop_12680_bielsko-biala" target="_blank">Booksy</a> lub zadzwoń do nas: <a href="tel:+48533146763">+48 533 146 763</a>
        </div>
      </div>
    </div>
  )
}
